import config from '../../config'
import select from 'dom-select'
export default (w) => {

    if ( w < 800 ) {
        config.col = 4
    } else if ( w > 800 && w < 1000 ) {
        config.col = 4
    } else if ( w > 1000 && w < 1300 ) {
        config.col = 4
    } else if ( w > 1300 && w < 1700 ) {
        config.col = 5
    } else {
        config.col = 6
    }

    let main = select('body')
    main.setAttribute('style', '--col: ' + config.col)
    return config.col
}