import Default from './default'
import Flickity from 'flickity'
import select from 'dom-select'
import event from 'dom-event'
import config from '../config'
import inView from 'in-view'
import $ from 'jquery'


class Clients extends Default {
	constructor() {
		super('clients')
	}

  mounted() {

      this.cache()
      this.init()

  }

  cache() {

    this.oldScroll
    this.projets = [...document.querySelectorAll('.projet')]

  }

  init() {

    this.projets.forEach((projet) => {
      this.saveProjetHeight(projet)
    })
    this.initCarousels()
    this.addEvents('on')

  }

  addEvents(type) {

      event[type](window, 'scroll', this.initMonique)
      
      const itemNavigationProjet = [...document.querySelectorAll('.nav_item')]
      itemNavigationProjet.forEach((item) => {

        event[type](item, 'click', this.toggleCat)
        $('html, body').animate( { scrollTop: 0 } )

      })

      config.isOne && event[type](select('.burger'), 'click', this.handlerBurger)

  }

  initCarousel(projet, key) {

    const images = [...projet.querySelectorAll('.carousel img')]

    if (images.length > 1) {

      const carousel = projet.querySelector('.carousel')
      carousel.classList.add('carousel_active')

      this.players[key] = new Flickity( carousel, {
        // options
        cellAlign: 'left',
        prevNextButtons: true,
        pageDots: false,
        draggable: false,
        wrapAround: true,
        autoPlay: false,
        imagesLoaded: true,
        lazyLoad: 2

      })
  
      projet.classList.add('carousel_init')

    } else {

      projet.classList.add('carousel_init')
      projet.querySelector('.carousel img').src = projet.querySelector('.carousel img').getAttribute('data-flickity-lazyload')

    }
  }

  initCarousels() {

    this.players = {}
    
    this.projets.forEach((projet, key) => {

      if (key == 0 || key == 1 ) {
        this.initCarousel(projet, key)
      } else {
        inView(`.projet[data-key="${key}"]`)
      .on('enter', () => {

        !projet.classList.contains('carousel_init') && this.initCarousel(projet, key)

      })
      }
        
    })

  }

  initMonique() {

    const animItems = [...document.querySelectorAll('.projet')],
          height = window.innerHeight,
          scroll = select('body').getBoundingClientRect().top

      animItems && animItems.forEach((item, key) => {

          const topPart    = item.getBoundingClientRect().top,
                bottomPart = item.getBoundingClientRect().bottom
    
    // SI JE SCROLL DOWN
    if (scroll < this.oldScroll) {

      // ENTRE PAR LE BAS 
      if (topPart < height && bottomPart > height) {


        if (!item.classList.contains('appeared')) {
          item.classList.add('appeared')
          const oldActive = select('.active')
          oldActive && oldActive.classList.remove('active')
      
        }

      }

      // OU SORT PAR LE HAUT
      if (topPart < 0 && bottomPart > 0) {


        item.classList.contains('appeared') && item.classList.remove('appeared')
        if (item.classList.contains('active')) {
          item.classList.remove('active')
        } 

      }

    } // SI JE SCROLL UP
    else {

      // ENTRE PAR LE HAUT
      if (bottomPart > 0 && topPart < 0) {


        if (!item.classList.contains('appeared')) {

          item.classList.add('appeared')
          const oldActive = select('.active')
          oldActive && oldActive.classList.remove('active')

        }
        
      }

      // SORT PAR LE BAS
      if(topPart > height && bottomPart < height) {


        item.classList.contains('appeared') && item.classList.remove('appeared')
        if (item.classList.contains('active')) {
          item.classList.remove('active')
        } 

      }

    }

  })

  this.oldScroll = scroll

  }

  handlerBurger() {

    select('.header').classList.contains('burger_open')
    ? select('.header').classList.remove('burger_open')
    : select('.header').classList.add('burger_open')

  }

  saveProjetHeight(projet) {

      const projetHeight = projet.querySelector('.wrapper_projet').offsetHeight
      projet.setAttribute('style', `--height: ${projetHeight}px`)

  }

  toggleCat(e) {

    config.isOne && select('.header').classList.contains('burger_open') && select('.header').classList.remove('burger_open')

    select('.header .select').classList.remove('select')
    const itemSelect = e.target

    if (!itemSelect.classList.contains('select')) {

      itemSelect.classList.add('select')
      const itemValue = itemSelect.dataset.value

      if(itemValue !== 'all') {

        this.projets.forEach((projet, key) => {
        
          if (projet.classList.contains(`${itemValue}`)) {
  
            !projet.classList.contains('carousel_init') && this.initCarousel(projet, key)

            if(projet.classList.contains('hide')) {
  
              projet.classList.remove('hide')
  
            }
  
          } else {
  
            if(!projet.classList.contains('hide')) {
  
              projet.classList.add('hide')
  
            }
  
          }
  
        })

      } else {

        this.projets.forEach((projet) => {

          if(projet.classList.contains('hide')) {
  
            projet.classList.remove('hide')

          }
        })
      } 
    }
  }

  resize () {

    this.projets.forEach((projet) => {

      const key = projet.dataset.key
      this.players[key] && this.players[key].resize()
      setTimeout(() => {this.saveProjetHeight(projet)},200)

    })
  }
}

export default Clients