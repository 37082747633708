import gsap from "gsap"

export default function fade(oldContainer, newContainer, done) {

		const tl = gsap.timeline()
		tl.to(oldContainer, {
			duration: 0.5,
			opacity: 0,
			ease: "power2.out",
		})
		tl.then(() => {
			oldContainer.style.display = 'none'
			newContainer.style.opacity = 0
			newContainer.style.visibility = 'visible'
			// scrollZero()
			if (!window.smooth) {
				window.scrollTo(0, 0, 0)
			}
		})
		tl.to(newContainer, {
			duration: 0.5,
			opacity: 1,
			ease: "power2.out",
		})
		tl.then(done)
}