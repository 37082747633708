import Clients from './home'


const sections = [

	new Clients,

]

export default sections
